import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const Editor = ({value,setDescriptionHandle,setIdHandle,setLangHandle,setNameHandle}) => {
  const handleChange = (e, editor) => {
    const data = editor.getData();
    // console.log(data)
    setDescriptionHandle(data);
    setIdHandle(value?.id);
    setLangHandle(value?.languageKey);
    setNameHandle(value?.name)
  };
  return (
    <div className="App">
      
      <CKEditor
        editor={ClassicEditor}

        data={value?.description}
        onReady={(editor) => {
        }}
        onChange={handleChange}
      />

    </div>
  );
};

export default Editor;
