import React, { useCallback, useState, useEffect } from "react";
import Toast from "../LoadingError/Toast";
import { Link } from "react-router-dom";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import MainPanel from "../main-panel/MainPanel";
import moment from "moment/moment";
import { BLOG_UPDATE_RESET } from "../../Redux/Constants/BlogConstants";
import { BASE_URL, PICTURE_URL } from "../../api/BaseConfig";

import {
  blogEditAction,
  blogUpdateAction,
} from "../../Redux/Actions/BlogAction";
import axios from "axios";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const EditBlogMain = (props) => {
  const { blogId } = props;
  let DefaultLanguageList = [
    { id: 1, langKey: "AZ" },
    { id: 2, langKey: "GB" },
    { id: 3, langKey: "RU" },
  ];

  

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [photoUrl, setPhotoUrl] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [language, setLanguage] = useState([]);
  const dispatch = useDispatch();

  const handlePicture=(e)=>{
    let data = new FormData();
     data.append('file', e, e.name);

    axios.post(BASE_URL+"/file",data,{
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      }
    }).then(x=>{setPhotoUrl(x.data)}).catch(err=>alert(err))
  }

  const onHandleLangChange = useCallback(
    (dataInfo) => {
    let languageList = {
        blogId: parseInt(blogId),
        id: parseInt(dataInfo.id),
        name: dataInfo.name,
        description: dataInfo.description,
        languageKey: dataInfo.lang,
      };
      setLanguage((ln) => [
        ...ln.filter(
          (l) => l.languageKey !== dataInfo.lang && l.languageKey !== "" && typeof(l.languageKey) !='undefined'
        ),
        languageList,
      ]);
    },
    [blogId]
    );
    
    

  const blogEdit = useSelector((state) => state.blogEdit);
  const { loading, error, blog } = blogEdit;

  const blogUpdate = useSelector((state) => state.blogUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = blogUpdate;

  

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: BLOG_UPDATE_RESET });
      toast.success("Blog Updated", ToastObjects);
    } else {
      if (!blog || blog.id !== parseInt(blogId)) {
        dispatch(blogEditAction(blogId));
      } else {
        setLanguage(blog.blogRecords);
        setIsFeatured(blog.isFeatured);
        setPhotoUrl(blog.photoUrl);
        setDate(moment(blog.blogDate).format("YYYY-MM-DD"));
      }
    }
  }, [blog, dispatch, blogId, successUpdate]);


  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      blogUpdateAction({
        id: blogId,
        blogDate: date,
        photoUrl,
        blogRecords: language.filter((l) => l.languageKey !== "" && typeof(l.languageKey) !='undefined'),
      })
    );
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/blogs" className="btn btn-danger text-white">
              Go to blogs
            </Link>
            <h2 className="content-title txt-primary">Update Blogs</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {errorUpdate && (
                    <Message variant="alert-danger">{errorUpdate}</Message>
                  )}
                  {loadingUpdate && <Loading />}
                  {loading ? (
                    <Loading />
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <>
                      <MainPanel
                        mainLang={DefaultLanguageList}
                        languageInfo={language}
                        changeLang={onHandleLangChange}
                      />
                      <div className="mb-4">
                        <label htmlFor="blog_date" className="form-label">
                          Date
                        </label>
                        <input
                          type="date"
                          placeholder="date"
                          className="form-control"
                          id="blog_date"
                          required
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="blog_featured" className="form-label">
                          Is Featured?
                        </label>
                        <input
                          type="checkbox"
                          id="blog_isFeatured"
                          value={isFeatured}
                          checked={isFeatured ? "checked" : ""}
                          onChange={(e) => {
                            setIsFeatured(e.target.checked ? true : false);
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Images</label>
                        {/* <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Image URL"
                          required
                          value={photoUrl}
                          onChange={(e) => setPhotoUrl(e.target.value)}
                        /> */}
                         <input 
                            className="form-control"
                            type='file'
                            placeholder="Choose image"
                            onChange={(e) => handlePicture(e.target.files[0])}
                             />
                            {/* <input className="form-control mt-3" type="file" 
                            /> */}
                            <img className="col-4" src={PICTURE_URL+photoUrl} alt=""/>
                        {/* <input className="form-control mt-3" type="file" /> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditBlogMain;