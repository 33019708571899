import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";
import MainPanel from "../main-panel/MainPanel";
import Message from "../LoadingError/Error";
import moment from "moment/moment";
import { blogAdd } from "../../Redux/Actions/BlogAction";
import { BLOG_CLEAR } from "../../Redux/Constants/BlogConstants";
import axios from "axios";
import { BASE_URL, PICTURE_URL } from "../../api/BaseConfig";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};
//language
const AddBlogMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const languageList = [
    { id: 1, langKey: "AZ" },
    { id: 2, langKey: "GB" },
    { id: 3, langKey: "RU" },
  ];

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [photoUrl, setPhotoUrl] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [language, setLanguage] = useState([]);

  const onChangeDate = (e) => {
    const newDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    setDate(newDate);
  };

  const handlePicture = (e) => {
    let data = new FormData();
    data.append("file", e, e.name);

    axios
      .post(BASE_URL + "/file", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((x) => {
        setPhotoUrl(x.data);
      })
      .catch((err) => alert(err));
  };

  const onHandleLangChange = useCallback((dataInfo) => {
    let languageList = {
      name: dataInfo.name,
      description: dataInfo.description,
      languageKey: dataInfo.lang,
    };
    setLanguage((ln) => [
      ...ln.filter(
        (l) => l.languageKey !== dataInfo.lang && l.languageKey !== ""
      ),
      languageList,
    ]);
  }, []);
  const blogCreate = useSelector((state) => state.blogAdd);
  const { loading, error, blog } = blogCreate;

  useEffect(() => {
    if (blog) {
      toast.success("Blog Added", ToastObjects);
      dispatch({ type: BLOG_CLEAR });
      setDate();
      setIsFeatured(false);
      setPhotoUrl("");
    }
  }, [blog, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    try {
      dispatch(
        blogAdd({
          blogDate: date,
          photoUrl,
          // isFeatured,
          blogRecords: language,
        })
      );
      history.push({
        pathname: "/blogs",
      });
    } catch {}
  };
  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/blogs" className="btn btn-danger text-white">
              Go to blogs
            </Link>
            <h2 className="content-title txt-primary">Add blog</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <MainPanel
                    mainLang={languageList}
                    changeLang={onHandleLangChange}
                    languageInfo={language}
                  />
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
                  <div className="mb-4">
                    <label htmlFor="blog_date" className="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      placeholder="Date"
                      className="form-control"
                      id="blog_date"
                      required
                      value={date}
                      onChange={onChangeDate}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="blog_featured" className="form-label">
                      Is Featured?
                    </label>
                    <input
                      className="mx-2"
                      type="checkbox"
                      id="featured_price"
                      value={isFeatured}
                      onChange={(e) => {
                        setIsFeatured(e.target.checked ? true : false);
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Images</label>
                    <input
                      className="form-control"
                      type="file"
                      placeholder="Choose image"
                      onChange={(e) => handlePicture(e.target.files[0])}
                    />
                    <img
                      className="col-4"
                      src={PICTURE_URL + photoUrl}
                      alt="image_blog"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddBlogMain;
