import React from "react";
import { Link } from "react-router-dom";
import  {useDispatch } from "react-redux";
import { blogDelete } from "../../Redux/Actions/BlogAction";
import moment from "moment/moment";
import { PICTURE_URL } from "../../api/BaseConfig";

const Blog = (props) => {
  const { blog } = props;
  
  const dispatch = useDispatch()
    
  const deleteHandler=(lang,id)=>{
    let result = window.confirm("Are you sure?")
    if(result){
     dispatch(blogDelete(lang,id))
    }
  }
  return (
    <>
      <div className="col-md-6 col-sm-6 col-lg-3 mb-5">
        <div className="card card-product-grid shadow-sm" >
          <Link to="#" className="img-wrap " >
            <img src={PICTURE_URL+blog.photoUrl} alt="Blog" className="w-full"  />
          </Link>
          <div className="info-wrap  ">
            <Link to="#" className="title text-truncate">
              {blog?.blogRecords?.name}
            </Link>
            <div className="date mb-2 px-2">{moment(blog?.blogDate.substring(0, 10)).format("MMMM D, YYYY")} <span style={{fontStyle: "italic"}}>published</span> </div>
            <div className="row m-2 ">
              <Link
                to={`/blog/${blog.id}/edit`}
                className="btn btn-sm btn-outline-success p-2 pb-3 col-md-6"
              >
                <i className="fas fa-pen"></i>
              </Link>
              <Link
                to="#"
                onClick={() => deleteHandler(blog.id)}
                className="btn btn-sm btn-outline-danger p-2 pb-3 col-md-6"
              >
                <i className="fas fa-trash-alt"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;