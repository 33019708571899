import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
} from "@chakra-ui/react";
import Editor from "../editor/Editor";

const MainPanel = ({
  mainLang,
  changeLang,
  languageInfo,
  descriptionShow = false,
}) => {
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [lang, setLang] = useState("");
  useEffect(() => {
    changeLang({ id, name, description, lang });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, name, description, lang]);
  return (
    <>
      <Tabs variant="enclosed">
        <TabList>
          {mainLang && mainLang.map((l) => (
            <Tab key={l.id}>{l.langKey}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {
            mainLang?.map((x) => (
              <>
                <TabPanel key={x.id}>
                  <div className="">
                    <label>Name</label>
                    <Input
                      mb={3}
                      type="hidden"
                      required
                      value={
                        languageInfo.find((l) => l.languageKey === x.langKey)
                          ?.id
                      }
                    />
                    <Input
                      mb={3}
                      type="text"
                      required
                      defaultValue={
                        languageInfo.find((l) => l.languageKey === x.langKey)
                          ?.name
                      }
                      onChange={(e) => {
                        setName(e.target.value);
                        setLang(x.langKey);
                        setId(
                          languageInfo.find((l) => l.languageKey === x.langKey)
                            ?.id
                        );
                        setDescription(
                          languageInfo.find((l) => l.languageKey === x.langKey)
                            ?.description
                        );
                      }}
                    />
                    {!descriptionShow && (
                      <div>
                        <label>Description</label>
                        <Editor
                          value={
                            languageInfo.find(
                              (l) => l.languageKey === x.langKey
                            )
                          }
                          setDescriptionHandle={
                            setDescription
                          }
                          setIdHandle={setId}
                          setLangHandle={setLang}
                          setNameHandle={setName}
                        />
                        {/* <Textarea type="text" required onChange={(e) => {}} /> */}
                      </div>
                    )}
                  </div>
                </TabPanel>
              </>
            ))}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default React.memo(MainPanel);
