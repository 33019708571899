import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryAddAction,
  listCategories,
} from "../../Redux/Actions/CategoryActions";
import {
  CATEGORY_ADD_RESET,
} from "../../Redux/Constants/CategoryConstants";
import { toast } from "react-toastify";
import MainPanel from "../main-panel/MainPanel";
import Toast from "../LoadingError/Toast";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const CreateCategory = () => {
  const dispatch = useDispatch();

  const languageList = [
    { id: 1, langKey: "AZ" },
    { id: 2, langKey: "GB" },
    { id: 3, langKey: "RU" },
  ];
  const [categoryId, setCategoryId] = useState(1);
  const [language, setLanguage] = useState([]);

  const onHandleLangChange = useCallback((dataInfo) => {
    let languageList = {
      name: dataInfo.name,
      languageKey: dataInfo.lang,
    };
    setLanguage((ln) => [
      ...ln.filter(
        (l) => l.languageKey !== dataInfo.lang && l.languageKey !== ""
      ),
      languageList,
    ]);
  }, []);

  const categoryAdd = useSelector((state) => state.categoryAdd);
  const {  category } = categoryAdd;
  // const { categories } = useSelector((state) => state.categoryList);
  useEffect(() => {
    if (category) {
      toast.success("Category Added", ToastObjects);
      dispatch({ type: CATEGORY_ADD_RESET });
      setCategoryId(null);
    }
  }, [category, dispatch]);
  
  useEffect(() => {
    dispatch(listCategories("AZ"));
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      categoryAddAction({
        categoryId: Number(categoryId),
        categoryRecords: language,
      })
    );
    // dispatch(listCategories("AZ"));
  };
  return (
    <>
    <Toast />
    <div className="col-md-12 col-lg-4">
      <h3 className="mb-4 fw-bold">Create Category</h3>
      <form onSubmit={submitHandler}>
        <div className="mb-4">
          <MainPanel
          descriptionShow={true}
            mainLang={languageList}
            changeLang={onHandleLangChange}
            languageInfo={language}
          />
        </div>

        {/* {errors.exampleRequired && <span>This field is required</span>} */}
        <div className="d-grid">
          <button className="btn btn-primary py-3">Create category</button>
        </div>
      </form>
    </div>
    </>
  );
};

export default CreateCategory;
