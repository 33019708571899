import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import Product from "../products/Product";
import { listProducts } from "../../Redux/Actions/ProductActions";
import ReactPaginate from "react-paginate";

const MainProducts = () => {

  const dispatch = useDispatch();
  const [page,setPage] =useState(1);
  const productList = useSelector((state) => state.productList);
  // console.log(productList)

  const { loading, error, product } = productList;

  
  const productDeleted = useSelector((state) => state.productDeleted);
  const { error: errorDelete, success: successDelete } = productDeleted;

  useEffect(() => {
    dispatch(listProducts(page));
  }, [dispatch, successDelete,page]);

  const handlePageClick =async (event)=>{
    setPage(event.selected +1)
  }

  return (
    <>
      <section className="content-main">
        <div className="content-header">
          <h2 className="content-title txt-primary">Products</h2>
          <div>
            <Link to="/addproduct" className="btn btn-primary">
              Create new
            </Link>
          </div>
        </div>

        <div className="card mb-4 shadow-sm">
          <header className="card-header bg-white ">
            <div className="row gx-3 py-3">
              <div className="col-lg-4 col-md-6 me-auto ">
                <input
                  type="search"
                  placeholder="Search..."
                  className="form-control p-2"
                />
              </div>
              {/* <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>All category</option>
                  <option>Electronics</option>
                  <option>Clothings</option>
                  <option>Something else</option>
                </select>
              </div>
              <div className="col-lg-2 col-6 col-md-3">
                <select className="form-select">
                  <option>Latest added</option>
                  <option>Cheap first</option>
                  <option>Most viewed</option>
                </select>
              </div> */}
            </div>
          </header>

          <div className="card-body">
            {errorDelete && (
              <Message variant="alert-danger">{errorDelete}</Message>
            )}
            {loading ? (
              <Loading />
            ) : error ? (
              <Message variant="alert-danger">{error}</Message>
            ) : (
              <div className="row">
                {product.map((product) => (
                  <Product product={product} key={product.id} />
                ))}
              </div>
            )}
            <ReactPaginate
            previousLabel={'previous '}
            nextLabel={'next'}
            breakLabel={'...'}
            pageCount={15}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
            renderOnZeroPageCount={1}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default MainProducts;
