export const BLOG_LIST_REQUEST="BLOG_LIST_REQUEST"
export const BLOG_LIST_SUCCESS="BLOG_LIST_SUCCESS"
export const BLOG_LIST_FAIL="BLOG_LIST_FAIL"
export const BLOG_CLEAR="BLOG_CLEAR"

export const BLOG_DELETE_REQUEST="BLOG_DELETE_REQUEST"
export const BLOG_DELETE_SUCCESS="BLOG_DELETE_SUCCESS"
export const BLOG_DELETE_FAIL="BLOG_DELETE_FAIL"

export const BLOG_ADD_REQUEST="BLOG_ADD_REQUEST"
export const BLOG_ADD_SUCCESS="BLOG_ADD_SUCCESS"
export const BLOG_ADD_FAIL="BLOG_ADD_FAIL"

export const BLOG_EDIT_REQUEST="BLOG_EDIT_REQUEST"
export const BLOG_EDIT_SUCCESS="BLOG_EDIT_SUCCESS"
export const BLOG_EDIT_FAIL="BLOG_EDIT_FAIL"

export const BLOG_UPDATE_REQUEST="BLOG_UPDATE_REQUEST"
export const BLOG_UPDATE_SUCCESS="BLOG_UPDATE_SUCCESS"
export const BLOG_UPDATE_FAIL="BLOG_UPDATE_FAIL"
export const BLOG_UPDATE_RESET="BLOG_UPDATE_RESET"