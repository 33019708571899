import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { productAdd } from "../../Redux/Actions/ProductActions";
import { PRODUCT_CLEAR } from "../../Redux/Constants/ProductConstants";
import Loading from "../LoadingError/Loading";
import Toast from "../LoadingError/Toast";
import MainPanel from "../main-panel/MainPanel";
import Message from "../LoadingError/Error";
import { listCategories } from "../../Redux/Actions/CategoryActions";
import { BASE_URL, PICTURE_URL } from "../../api/BaseConfig";
import axios from "axios";
import FormData from 'form-data'

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const AddProductMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const languageList = [
    { id: 1, langKey: "AZ" },
    { id: 2, langKey: "GB" },
    { id: 3, langKey: "RU" },
  ];

  const [price, setPrice] = useState();
  const [discount, setDiscount] = useState();
  const [categoryID, setCategoryID] = useState(null);
  const [photoUrl, setPhotoUrl] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [language, setLanguage] = useState([]);

  const onHandleLangChange = useCallback((dataInfo) => {
    let languageList = {
      name: dataInfo.name,
      description: dataInfo.description,
      languageKey: dataInfo.lang,
    };
    setLanguage((ln) => [
      ...ln.filter(
        (l) => l.languageKey !== dataInfo.lang && l.languageKey !== ""
      ),
      languageList,
    ]);
  }, []);
  const productCreate = useSelector((state) => state.productAdd);
  const { loading, error, product } = productCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  useEffect(() => {
    if (product) {
      toast.success("Product Added", ToastObjects);
      dispatch({ type: PRODUCT_CLEAR });
      setDiscount(0);
      setIsFeatured(false);
      setPrice(0);
      setPhotoUrl("");
    }
  }, [product, dispatch]);

  useEffect(() => {
    dispatch(listCategories("AZ"));
  }, [dispatch]);

  const handlePicture = (e) => {
    let data = new FormData();
    data.append("file", e, e.name);

    axios
      .post(BASE_URL + "/file", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((x) => {
        setPhotoUrl(x.data);
      })
      .catch((err) => alert(err));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    try {
      dispatch(
        productAdd({
          price,
          discount,
          categoryId: Number(categoryID),
          photoUrl,
          ProductRecords: language,
        })
      );
      history.push({
        pathname: "/products",
      });
    } catch {}
  };

  const handleChange = (event) => {
    setCategoryID(event.target.value);
  };

  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/products" className="btn btn-danger text-white">
              Go to products
            </Link>
            <h2 className="content-title txt-primary ">Add product</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  <MainPanel
                    mainLang={languageList}
                    changeLang={onHandleLangChange}
                    languageInfo={language}
                  />
                  {error && <Message variant="alert-danger">{error}</Message>}
                  {loading && <Loading />}
                  <div className="mb-4">
                    <label htmlFor="product_categories" className="form-label">
                      Categories
                    </label>
                    <select
                      id="product_categories"
                      value={Number(categoryID)}
                      onChange={handleChange}
                      required
                      className="form-control"
                    >
                      <option selected option disabled value={0}>
                        --Select Categories--
                      </option>
                      {categories?.map((category) => (
                        <option
                          value={Number(category.id)}
                          key={Number(category.id)}
                          // value={Number(category.categoryId)}
                        >
                          {category.categoryRecords[0]?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      placeholder="Price"
                      className="form-control"
                      id="product_price"
                      required
                      // value={price}
                      onChange={(e) => setPrice(Number(e.target.value))}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_discount" className="form-label">
                      Discount
                    </label>
                    <input
                      type="number"
                      placeholder="Discount"
                      className="form-control"
                      id="product_discount"
                      // value={discount}
                      onChange={(e) => setDiscount(Number(e.target.value))}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="product_featured" className="form-label">
                      Is Featured?
                    </label>
                    <input
                      className="mx-2"
                      type="checkbox"
                      id="product_price"
                      value={isFeatured}
                      onChange={(e) => {
                        setIsFeatured(e.target.checked ? true : false);
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="form-label">Images</label>
                    <input
                      className="form-control"
                      type="file"
                      placeholder="Choose image"
                      onChange={(e) => handlePicture(e.target.files[0])}
                    />
                    <img className="col-4" src={PICTURE_URL + photoUrl} alt="image_product" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default AddProductMain;
