import { configureStore,combineReducers } from '@reduxjs/toolkit'
import { LoginReducers, RegisterReducers } from './Reducers/UserReducers';
import thunk from 'redux-thunk';
import { categoryAddReducers, categoryDeleteReducers, categoryEditReducers, categoryListReducers, categoryUpdateReducer } from './Reducers/CategoryReducers';
import { productDeleteReducer, productEditReducer, productListReducers, productAddReducers, productUpdateReducer } from './Reducers/ProductReducers';
import { blogAddReducers, blogDeleteReducer, blogEditReducer, blogListReducers, blogUpdateReducer } from './Reducers/BlogReducer';


const reducer=combineReducers({
    loginUser:LoginReducers,
    registerUser:RegisterReducers,
    productAdd:productAddReducers,
    productList:productListReducers,
    productDeleted:productDeleteReducer,
    productEdit:productEditReducer,
    productUpdate:productUpdateReducer,
    categoryList:categoryListReducers,
    categoryDeleted:categoryDeleteReducers,
    categoryEdit:categoryEditReducers,
    categoryUpdate:categoryUpdateReducer,
    categoryAdd:categoryAddReducers,
    blogList:blogListReducers,
    blogAdd:blogAddReducers,
    blogEdit:blogEditReducer,
    blogUpdate:blogUpdateReducer,
    blogDeleted:blogDeleteReducer
})

const userFromLocalStorage=localStorage.getItem("userInfo")?
 JSON.parse(localStorage.getItem("userInfo")): null

 
const initialState={
    loginUser:{
        userInfo:userFromLocalStorage
    }
}
const store=configureStore({reducer,initialState,middleware:[thunk]});

export default store;

