import axios from "axios";
import { BASE_URL } from "../../api/BaseConfig";
import {
  BLOG_ADD_FAIL,
  BLOG_ADD_REQUEST,
  BLOG_ADD_SUCCESS,
  BLOG_DELETE_FAIL,
  BLOG_DELETE_REQUEST,
  BLOG_DELETE_SUCCESS,
  BLOG_EDIT_FAIL,
  BLOG_EDIT_REQUEST,
  BLOG_EDIT_SUCCESS,
  BLOG_LIST_FAIL,
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_UPDATE_FAIL,
  BLOG_UPDATE_REQUEST,
  BLOG_UPDATE_SUCCESS,
} from "../Constants/BlogConstants";


//create Blog
export const blogAdd = (blogData) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_ADD_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    const { data } = await axios.post(
      `${BASE_URL}/Blog`,
      JSON.stringify(blogData),
      config
    );  
    dispatch({ type: BLOG_ADD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BLOG_ADD_FAIL, payload: error });
  }
};

export const blogUpdateAction = (blog) => async (dispatch,getstate) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    dispatch({ type: BLOG_UPDATE_REQUEST });
    const { data } = await axios.put(
      `${BASE_URL}/Blog/${blog.id}`,
      JSON.stringify(blog),
      config
    );
    dispatch({ type: BLOG_UPDATE_SUCCESS, payload: data });
    dispatch({ type: BLOG_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BLOG_UPDATE_FAIL, payload: error });
  }
};

//GetAll BLOG blogpa
export const listBlogs = (pageNo) => async (dispatch,getstate) => {
  try {
    dispatch({ type: BLOG_LIST_REQUEST });
    const { data } = await axios.get(`${BASE_URL}/Blog/all/AZ/${pageNo}`);
    dispatch({ type: BLOG_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BLOG_LIST_FAIL, payload: error });
  }
};

//Edit BLOG
export const blogEditAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_EDIT_REQUEST });
    const { data } = await axios.get(`${BASE_URL}/Blog/GetbyIdWithLangBlog/${id}`);
    dispatch({ type: BLOG_EDIT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BLOG_EDIT_FAIL, payload: error });
  }
};

//delete BLOG
export const blogDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_DELETE_REQUEST });
   await axios.delete(`${BASE_URL}/Blog/${id}`);
    dispatch({
      type: BLOG_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BLOG_DELETE_FAIL,
      payload: error,
    });
  }
};


