export const LOGIN_USER_REQUEST="LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS="LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL="LOGIN_USER_FAIL";
export const LOGIN_USER_LOGOUT="LOGIN_USER_LOGOUT";

export const REGISTER_USER_REQUEST="REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS="REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL="REGISTER_USER_FAIL";

export const USER_DETAILS_REQUEST="USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS="USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL="USER_DETAILS_FAIL";
export const USER_DETAILS_RESET="USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST="USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS="USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL="USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET="USER_UPDATE_PROFILE_RESET";

