import {
    BLOG_ADD_FAIL,
    BLOG_ADD_REQUEST,
    BLOG_ADD_SUCCESS,
    BLOG_CLEAR,
    BLOG_DELETE_FAIL,
    BLOG_DELETE_REQUEST,
    BLOG_DELETE_SUCCESS,
    BLOG_EDIT_FAIL,
    BLOG_EDIT_REQUEST,
    BLOG_EDIT_SUCCESS,
    BLOG_LIST_FAIL,
    BLOG_LIST_REQUEST,
    BLOG_LIST_SUCCESS,
    BLOG_UPDATE_FAIL,
    BLOG_UPDATE_REQUEST,
    BLOG_UPDATE_RESET,
    BLOG_UPDATE_SUCCESS,
  } from "../Constants/BlogConstants";
  
  export const blogAddReducers = (state = { blogInfo: {} }, action) => {
    switch (action.type) {
      case BLOG_ADD_REQUEST:
        return { loading: true };
      case BLOG_ADD_SUCCESS:
        return { loading: false, success: true, blog: action.payload };
      case BLOG_ADD_FAIL:
        return { loading: false, error: action.payload };
      case BLOG_CLEAR:
        return {};
      default:
        return state;
    }
  };
  
  export const blogListReducers = (state = { blog: [] }, action) => {
    switch (action.type) {
      case BLOG_LIST_REQUEST:
        return { loading: true };
      case BLOG_LIST_SUCCESS:
        return { loading: false, blog: action.payload };
      case BLOG_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  export const blogEditReducer = (
    state = { blog: {} },
    action
  ) => {
    switch (action.type) {
      case BLOG_EDIT_REQUEST:
        return { ...state, loading: true };
      case BLOG_EDIT_SUCCESS:
        return { loading: false, blog: action.payload };
      case BLOG_EDIT_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  //Update BLOG
  export const blogUpdateReducer = (state = { blog: {} }, action) => {
    switch (action.type) {
      case BLOG_UPDATE_REQUEST:
        return { ...state, loading: true };
      case BLOG_UPDATE_SUCCESS:
        return { loading: false, success: true, blog: action.payload };
      case BLOG_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case BLOG_UPDATE_RESET:
        return { blog: {} };
      default:
        return state;
    }
  };
  export const blogDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case BLOG_DELETE_REQUEST:
        return { loading: true };
      case BLOG_DELETE_SUCCESS:
        return { loading: false, success: true };
      case BLOG_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  