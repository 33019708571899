import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {useSelector} from "react-redux"
import jwt_decode from "jwt-decode";

function PrivateRouter({ component: Component, ...rest }) {
    const {userInfo}=useSelector(state=>state.loginUser)
     localStorage.setItem('token', userInfo);
    const [userTokenInfo,setUserTokenInfo]=useState("");
    useEffect(()=>{
        if(userInfo && userInfo.token){
            setUserTokenInfo(jwt_decode(userInfo.token));
        }
    },[userInfo])
  return (
    <Route
      {...rest}
      component={(props) => {
        const token=window.localStorage.getItem("userInfo")
        // console.log(token)
        if (token) {
          return <Component {...props} />;
        } else {
          return <Redirect to={`/login`} />;
        }
      }}
    />
  );
}

export default PrivateRouter;
