import React, { useCallback, useState, useEffect } from "react";
import Toast from "./../LoadingError/Toast";
import { Link } from "react-router-dom";
import Message from "../LoadingError/Error";
import Loading from "../LoadingError/Loading";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import MainPanel from "../main-panel/MainPanel";
import {
  productEditAction,
  productUpdateAction,
} from "../../Redux/Actions/ProductActions";
import { PRODUCT_UPDATE_RESET } from "../../Redux/Constants/ProductConstants";
import { listCategories } from "../../Redux/Actions/CategoryActions";
import { BASE_URL, PICTURE_URL } from "../../api/BaseConfig";
import axios from "axios";
import FormData from "form-data";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const EditProductMain = (props) => {
  const { productId } = props;
  const DefaultLanguageList = [
    { id: 1, langKey: "AZ" },
    { id: 2, langKey: "GB" },
    { id: 3, langKey: "RU" },
  ];

  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [categoryID, setCategoryID] = useState(null);
  const [photoUrl, setPhotoUrl] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [language, setLanguage] = useState([]);
  const dispatch = useDispatch();
  const onHandleLangChange = useCallback(
    (dataInfo) => {
      let languageList = {
        productId: parseInt(productId),
        id: parseInt(dataInfo.id),
        name: dataInfo.name,
        description: dataInfo.description,
        languageKey: dataInfo.lang,
      };
      setLanguage((ln) => [
        ...ln.filter(
          (l) =>
            l.languageKey !== dataInfo.lang &&
            l.languageKey !== "" &&
            typeof l.languageKey != "undefined"
        ),
        languageList,
      ]);
    },
    [productId]
  );

  const productEdit = useSelector((state) => state.productEdit);
  const { loading, error, product } = productEdit;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      toast.success("Product Updated", ToastObjects);
    } else {
      if (!product || product.id !== parseInt(productId)) {
        dispatch(productEditAction(productId));
      } else {
        setDiscount(product.discount);
        setCategoryID(product.categoryId);
        setLanguage(product.productRecords);
        setIsFeatured(product.isFeatured);
        setPrice(product.price);
        dispatch(listCategories("AZ"));
        setPhotoUrl(product.photoUrl);
      }
    }
  }, [product, dispatch, productId, successUpdate]);

  const handlePicture = (e) => {
    let data = new FormData();
    data.append("file", e, e.name);

    axios
      .post(BASE_URL + "/file", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((x) => {
        setPhotoUrl(x.data);
      })
      .catch((err) => alert(err));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      productUpdateAction({
        id: parseInt(productId),
        price,
        discount,
        categoryId: parseInt(categoryID),
        photoUrl,
        productRecords: language.filter(
          (l) => l.languageKey !== "" && typeof l.languageKey != "undefined"
        ),
      })
    );
  };

  const handleChange = (event) => {
    setCategoryID(event.target.value);
  };
  return (
    <>
      <Toast />
      <section className="content-main" style={{ maxWidth: "1200px" }}>
        <form onSubmit={submitHandler}>
          <div className="content-header">
            <Link to="/products" className="btn btn-danger text-white">
              Go to products
            </Link>
            <h2 className="content-title">Update Product</h2>
            <div>
              <button type="submit" className="btn btn-primary">
                Publish now
              </button>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-xl-12 col-lg-12">
              <div className="card mb-4 shadow-sm">
                <div className="card-body">
                  {errorUpdate && (
                    <Message variant="alert-danger">{errorUpdate}</Message>
                  )}
                  {loadingUpdate && <Loading />}
                  {loading ? (
                    <Loading />
                  ) : error ? (
                    <Message variant="alert-danger">{error}</Message>
                  ) : (
                    <>
                      {/* {language &&
                        language.length >0 ( */}
                      <MainPanel
                        languageInfo={language}
                        mainLang={DefaultLanguageList}
                        changeLang={onHandleLangChange}
                      />
                      {/* )} */}
                      {categories && (
                        <div className="mb-4">
                          <label
                            htmlFor="product_categories"
                            className="form-label"
                          >
                            Categories
                          </label>

                          <select
                            id="product_categories"
                            value={categoryID}
                            onChange={handleChange}
                            required
                            className="form-control"
                          >
                            <option selected option disabled value="-">
                              Select Categories...
                            </option>
                            {categories?.map((category) => (
                              <option value={parseInt(category.id)}>
                                {category.categoryRecords[0]?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      <div className="mb-4">
                        <label htmlFor="product_price" className="form-label">
                          Price
                        </label>
                        <input
                          type="number"
                          placeholder="Type here"
                          className="form-control"
                          id="product_price"
                          required
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <label htmlFor="product_summary" className="form-label">
                          Discount
                        </label>
                        <input
                          type="text"
                          placeholder="Discount"
                          className="form-control"
                          id="product_summary"
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="product_featured"
                          className="form-label"
                        >
                          Is Featured?
                        </label>
                        <input
                          type="checkbox"
                          id="product_price"
                          value={isFeatured}
                          checked={isFeatured ? "checked" : ""}
                          onChange={(e) => {
                            setIsFeatured(e.target.checked ? true : false);
                          }}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="form-label">Images</label>
                        <input
                          className="form-control"
                          type="file"
                          placeholder="Choose image"
                          onChange={(e) => handlePicture(e.target.files[0])}
                        />
                        <img className="col-4" src={PICTURE_URL + photoUrl} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default EditProductMain;
