import axios from "axios";
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_FAIL,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_LOGOUT,
} from "../Constants/UserConstants";
import { BASE_URL } from "../../api/BaseConfig";
import { toast } from "react-toastify";

export const loginAction = (email, password) => async (dispatch) => {
  const ToastObjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    autoClass: 2000,
  };

  try {
    dispatch({ type: LOGIN_USER_REQUEST });
    const config = {
      header: {
        "Content-Type": "appilcation/json",
      },
    };
    const { data } = await axios.post(
      `${BASE_URL}/Account/login`,
      { email, password },
      config
    );  

    if (!data.isAdmin === false) {
      toast.error("You are not Admin", ToastObjects);
      dispatch({
        type: LOGIN_USER_FAIL,
      });
    } else {
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: data,
      });
    }

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    const message=
    error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
    if(message === "Not authorized,token failed"){
        dispatch(logout())
    }
    dispatch({
      type: LOGIN_USER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerAction =
  (firstname, lastname, email, password, confirmPassword) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: REGISTER_USER_REQUEST });
      const { data } = await axios.post(`${BASE_URL}/Account/register`, {
        firstname,
        lastname,
        email,
        password,
        confirmPassword,
      });
      dispatch({
        type: REGISTER_USER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REGISTER_USER_FAIL,
        payload: error.message,
      });
    }
  };

//LOGOUT
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({ type: LOGIN_USER_LOGOUT });
//   dispatch({ type: USER_DETAILS_RESET });
};
