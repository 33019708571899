export const CATEGORY_LIST_REQUEST="CATEGORY_LIST_REQUEST"
export const CATEGORY_LIST_SUCCESS="CATEGORY_LIST_SUCCESS"
export const CATEGORY_LIST_FAIL="CATEGORY_LIST_FAIL"

export const CATEGORY_UPDATE_REQUEST="CATEGORY_UPDATE_REQUEST"
export const CATEGORY_UPDATE_SUCCESS="CATEGORY_UPDATE_SUCCESS"
export const CATEGORY_UPDATE_FAIL="CATEGORY_UPDATE_FAIL"

export const CATEGORY_ADD_REQUEST="CATEGORY_ADD_REQUEST"
export const CATEGORY_ADD_SUCCESS="CATEGORY_ADD_SUCCESS"
export const CATEGORY_ADD_FAIL="CATEGORY_ADD_FAIL"
export const CATEGORY_ADD_RESET="CATEGORY_ADD_RESET"

export const CATEGORY_DELETE_REQUEST="CATEGORY_DELETE_REQUEST"
export const CATEGORY_DELETE_SUCCESS="CATEGORY_DELETE_SUCCESS"
export const CATEGORY_DELETE_FAIL="CATEGORY_DELETE_FAIL"

export const CATEGORY_EDIT_REQUEST="CATEGORY_EDIT_REQUEST"
export const CATEGORY_EDIT_SUCCESS="CATEGORY_EDIT_SUCCESS"
export const CATEGORY_EDIT_FAIL="CATEGORY_EDIT_FAIL"
